import enterView from "enter-view";

export default function () {
	setupIsVisibleBlocks();
}

function setupIsVisibleBlocks() {
	const selector = ".l-home-fold, .l-home-about, .l-page__section";
	const $siteBlocks = document.querySelectorAll(selector);

	if ($siteBlocks.length > 0) {
		enterView({
			selector: selector,
			enter: function (el) {
				window.setTimeout((_) => el.classList.add("is-visible"), 5);
			},
			offset: 0.5,
			once: true,
		});
	}
}
