import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../bitlink/templates/jinja2/svgs/sprite.svg",
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";

applyDomPolyfills();

// Production code goes after here
import { OutdatedBrowserNotice } from "@neonjungle/birdseed/browser/OutdatedBrowserNotice";
import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";

import initAccordions from "./includes/accordion";
import animations from "./includes/animations";
import attributesForCSS from "./includes/attributesForCSS";
import curriculumBlock from "./includes/curriculumBlock";
import renderSnippets from "./includes/makeCodeRender";
import menuActions from "./includes/menuActions";
import updateClampedCSS from "./includes/updateClampedCSS";

// Should happen before the page has finished loading
// to update font sizes ASAP
updateClampedCSS();

document.addEventListener("DOMContentLoaded", function () {
	window.setTimeout((_) => {
		document.body.classList.remove("preload");
		document.body.classList.add("loaded");
	}, 50);

	animations();
	menuActions();
	attributesForCSS();
	initAccordions(true);
	curriculumBlock();
	renderSnippets();

	new OutdatedBrowserNotice(false, (browserOutdated) => {
		if (browserOutdated) {
			document.body.classList.add("browser-is-outdated");
		}
	});

	const $homeFold = document.querySelector(".l-home-fold");
	if ($homeFold) {
		const $siteHeader = document.querySelector(".l-site-header");
		const setHeaderHight = (_) => {
			const offset = window.innerWidth >= 768 ? 150 : 50;
			document.documentElement.style.setProperty(
				"--header-overflow-gfx-height",
				`${$homeFold.offsetHeight + $siteHeader.offsetHeight - offset}px`,
			);
		};
		setHeaderHight();

		window.addEventListener("resize", (e) => {
			setHeaderHight();
		});
	}

	class HistoryAjaxForm extends AjaxForm {
		handleError(message) {
			document.body.classList.remove("thinking");
			super.handleError(message);
		}
		beforeFetch() {
			document.body.classList.add("thinking");
		}
		afterFetch(responseJson) {
			window.history.pushState({}, "", responseJson.url);
			initAccordions(true);
			document.body.classList.remove("thinking");
		}
	}

	const $contentForm = document.querySelector("[data-content-form]");
	if ($contentForm) {
		const $accordion = document.querySelector("[data-accordion]");
		const $contentFormAjax = new HistoryAjaxForm($contentForm, $accordion);
		$contentForm.querySelectorAll("input").forEach(($formEl) => {
			$formEl.addEventListener("change", (e) => {
				$contentFormAjax.fetchAndReplace();
			});
		});
	}
});
