function menuActions() {
	const $navTrigger = document.querySelector("[data-navigation-trigger]");
	const $navTarget = document.querySelector("[data-navigation-target]");
	const $mobileMenus = document.querySelectorAll("[data-mobile-submenu]");

	if ($navTrigger && $navTarget) {
		const $navLinks = $navTarget.querySelectorAll(
			"a:not([data-mobile-submenu-back])",
		);

		$navTrigger.addEventListener("click", (e) => {
			$navTrigger.classList.toggle("is-active");
			$navTarget.classList.toggle("is-active");
			document.body.classList.toggle("menu-open");

			$mobileMenus.forEach(($submenu) => {
				$submenu
					.querySelector("[data-mobile-submenu-list]")
					.classList.remove("is-active");
			});

			e.preventDefault();
		});

		$navLinks.forEach(($l) => {
			$l.addEventListener("click", (e) => {
				$navTrigger.classList.toggle("is-active");
				$navTarget.classList.toggle("is-active");
				document.body.classList.remove("menu-open");
			});
		});
	}

	if ($mobileMenus) {
		$mobileMenus.forEach(($submenu) => {
			const $trigger = $submenu.querySelector("[data-mobile-submenu-opener]");
			const $target = $submenu.querySelector("[data-mobile-submenu-list]");

			$trigger.addEventListener("click", (e) => {
				$target.classList.add("is-active");
				e.preventDefault();
			});

			$submenu
				.querySelector("[data-mobile-submenu-back]")
				.addEventListener("click", (e) => {
					$target.classList.remove("is-active");
					e.preventDefault();
				});
		});
	}
}

export default menuActions;
